<template>
  <div class="home">
    <Navigation />
    <div class="section bg-primary-3">
      <div class="container text-center">
        <h1>Instagram Stories</h1>
        <div class="text-large">Spice up your social media presence.</div>
      </div>
    </div>
    <Templates Category="instagram-stories" Workspace="extras" Downloadable />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Templates from "@/components/TemplateCollection.vue";

export default {
  name: "home",
  components: {
    Navigation,
    Templates
  }
};
</script>
